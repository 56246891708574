export default [

  {
    title: 'Orders',
    route: 'home',
    icon: 'ShoppingCartIcon',
  },

  {
    title: 'Clients & Scans',
    route: 'scans',
    icon: 'SmileIcon',
  },
 
  

]
